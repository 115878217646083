import axios from 'axios';
import { authContext } from 'Auth';

export class UploadService{

    public static ImportFile=(file:any, toAdded: boolean)=>{
        return axios({
            method: 'POST',
            url: 'api/sheet/import-sheet',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'fileName': encodeURI(file.name),
                'userName': encodeURI(authContext.getCachedUser().userName),
                'toAdded': toAdded
            },
            data: file
        });
    }

    public static GetFiles=():Promise<any>=>{
        return axios.get(`api/sheet/get-files?userName=${authContext.getCachedUser().userName}`);
    }
}