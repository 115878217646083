export class Utils{

    public static getShortDateTime = (_dateTime: Date): string => {
        let date = new Date(_dateTime);
        var options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        };
        return date.getFullYear() < 2000 ? '' : date.toLocaleString("fr", options);
    }

    public static getFileNameFromHeaders=(headers:any):string=>{
        var _filename="";
        const disposition = headers["content-disposition"];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) { 
                _filename = matches[1].replace(/['"]/g, '');
            }
        }
        return _filename;
    }
}