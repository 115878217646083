import React from 'react';
import {DropzoneArea} from 'material-ui-dropzone';
import './UploadFile.scss';

interface UploadFileProperties{
    handleChange:(files)=>void,
    handleRejectMessage:(files, evt)=>void
}

export const UploadFile = (props:UploadFileProperties)=>{

    return (
        <DropzoneArea 
            maxFileSize={10000000}
            onChange={props.handleChange} dropzoneClass="drop-zone-content"
            acceptedFiles={['.xlsx']}
            showAlerts={false}
            filesLimit={1}
            onDropRejected={props.handleRejectMessage}
            dropzoneParagraphClass="drop-zone-text"
            dropzoneText="Déposez ou cliquez ici pour parcourir votre fiche de vente"
        />
    );
}