﻿import axios from 'axios';
import { authContext } from '../Auth';

export class FicheService {
    public static getLastFiches = (): Promise<any> => {
        return axios.get(`api/sheet/get-lastfiches?login=${encodeURI(authContext.getCachedUser().profile.unique_name)}`);
    }
    public static getFiche = (id: number): Promise<any> => {
        return axios.get(`api/sheet/get-fiche?id=${id}`);
    }
    public static getMyAgencies = (): Promise<any> => {
        return axios.get(`api/sheet/get-myAgencies?login=${encodeURI(authContext.getCachedUser().profile.unique_name)}`);
    }
    public static saveFiche = (fiche) => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        return axios.post(`api/sheet/save-fiches`, fiche, options);
    }
}
