import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { runWithAdal } from 'react-adal';
import {authContext} from './Auth';

const baseUrl = "/";
const rootElement = document.getElementById('root');

runWithAdal(authContext, () => {
  ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>,
    rootElement);
    //registerServiceWorker();
  }, false);