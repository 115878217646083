import React, { Component } from 'react';
import { UploadFile } from './Upload/UploadFile';
import { FicheService } from './FicheService';
import { UploadService } from './Upload/UploadService';
import { FileLightModel } from 'model/FileLightModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lodash';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import { Snackbar, Dialog, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem, DialogContent, IconButton, Typography, DialogActions, Button, Box, Fade, CircularProgress, Table, TableRow, Paper, TableHead, TableCell, TableBody, TableContainer } from '@material-ui/core';
import { MySnackbarContentWrapper } from 'shared/MySnackbarContentWrapper';
import '../index.scss';
import '../conf/axios-conf';
import { FicheDrawerComponent } from './Fiche/FicheComponent';
import { ImportSheetLightModel } from 'model/ImportSheetLightModel';
import { Utils } from 'Helper/Utils';
import { FicheLightModel } from '../model/FicheLightModel';
import { FilterLightModel } from '../model/FilterLightModel';
import { UELightModel } from '../model/UELightModel';
import NumberFormat from 'react-number-format';

interface HomeState {
    newLstFiles: FileLightModel[],
    isInfoToastVisible: boolean,
    isErrorToastVisible: boolean,
    isSuccessToastVisible: boolean,
    toastMessageSuccess: string,
    toastMessageError: string,
    toastMessageInfo: string,
    toastLstMessageError: string[],
    openDialog: boolean,
    dialogMessage: string,
    loading: boolean,
    files: [],
    myAgencies: Array<UELightModel>,
    fiches: Array<FicheLightModel>,
    filteredFiches: Array<FicheLightModel>,
    filter: FilterLightModel,
    selectedFiche: FicheLightModel,
    isFicheDrawerOpened: boolean
};

export const states = [
    {
        id: 0,
        state: "Tous les statuts"
    },
    {
        id: 1,
        state: "Gagné"
    },
    {
        id: 2,
        state: "Perdu"
    },
    {
        id: 3,
        state: "En attente d'attribution"
    },
    {
        id: 4,
        state: "Excusé"
    },
    {
        id: 5,
        state: "Non soumissionné"
    },
    {
        id: 6,
        state: "Infructueux"
    }
];

export class Home extends Component<{}, HomeState> {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            newLstFiles: [],
            isSuccessToastVisible: false,
            isErrorToastVisible: false,
            isInfoToastVisible: false,
            toastMessageSuccess: '',
            toastMessageInfo: '',
            toastMessageError: '',
            toastLstMessageError: [],
            loading: false,
            openDialog: false,
            dialogMessage: '',
            files: [],
            myAgencies: [],
            fiches: [],
            filteredFiches: [],
            filter: {
                ue: {
                    label: '',
                    identifier: ''
                },
                attributionDate: null,
                moe: '',
                state: 'Tous les statuts'
            },
            selectedFiche: null,
            isFicheDrawerOpened: false
        };
    }

    componentDidMount() {
        this.getMyAgencies();
        this.getMyFiches();
    }

    getMyFiches = () => {
        FicheService.getLastFiches()
            .then(res => {
                let _data = res.data ? res.data : [];
                this.setState({
                    fiches: _data,
                    filteredFiches: _data
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    getMyAgencies = () => {
        FicheService.getMyAgencies()
            .then(res => {
                let { filter } = this.state;
                let _data = res.data ? res.data : [];
                if (_data.length == 1) {
                    filter.ue = _data[0];
                }
                else {
                    _data.unshift({
                        label: 'Toutes mes agences',
                        identifier: ''
                    });
                }
                this.setState({
                    filter: filter,
                    myAgencies: _data
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    handleNewLstFiles = (newLstFiles: FileLightModel[]) => {
        this.setState({
            newLstFiles: newLstFiles
        });
    }

    //#region Dialog, snackbar functions
    handleSuccessClose = () => {
        this.setState({ isSuccessToastVisible: false });
    }

    handleInfoClose = () => {
        this.setState({ isInfoToastVisible: false });
    }

    handleErrorClose = () => {
        this.setState({ isErrorToastVisible: false });
    }

    handleDialogClose = () => {
        this.setState({
            openDialog: false
        });
        this.clearFiles();
    }

    handleDialogOpen = (fileName, lastWriterFirstNameUser, lastWriterLastNameUser, lastWriterTime) => {
        this.setState({
            openDialog: true,
            loading: false,
            isErrorToastVisible: false,
            isSuccessToastVisible: false,
            isInfoToastVisible: false,
            dialogMessage: `La fiche "${fileName}" a déjà été chargé par: ${lastWriterFirstNameUser} ${lastWriterLastNameUser}, le ${Utils.getShortDateTime(lastWriterTime)}! Voulez-vous la mettre à jour ?`
        });
    }
    //#endregion

    //#region Edition Fiches
    handleOpenEdition = (fiche: FicheLightModel) => {
        this.setState({
            isFicheDrawerOpened: true,
            selectedFiche: fiche
        });
    }

    handleCloseDrawer = () => {
        this.setState({
            isFicheDrawerOpened: false,
            isErrorToastVisible: false,
            isSuccessToastVisible: false,
            isInfoToastVisible: true,
            toastMessageInfo: "Edition annulée.",
            loading: false
        });
        this.getMyFiches();
    }

    handleDialogEditionSuccess = () => {
        this.setState({
            isFicheDrawerOpened: false,
            isErrorToastVisible: false,
            isSuccessToastVisible: true,
            isInfoToastVisible: false,
            toastMessageSuccess: "La fiche a été mise à jour avec succès !",
            loading: false
        });
    }
    //#endregion

    //#region filter
    handleChangeAgency = (e) => {
        let { filter, myAgencies } = this.state;
        let a = myAgencies.find(i => i.identifier == e.target.value);
        filter.ue = a;
        this.filterFiches(filter);
    }
    handleChangeState = (e) => {
        let { filter } = this.state;
        filter.state = states[e.target.value].state;
        this.filterFiches(filter);
    }
    handleChangeAtrDate = (e) => {
        let { filter } = this.state;
        filter.attributionDate = e;
        this.filterFiches(filter);
    }
    handleChangeMOEFilter = (option) => {
        let { filter } = this.state;
        filter.moe = option.target.value;
        this.filterFiches(filter);
    }
    resetFilter = () => {
        let { filter } = this.state;
        filter = {
            ue: {
                label: '',
                identifier: ''
            },
            attributionDate: null,
            moe: '',
            state: 'Tous les statuts'
        };
        this.filterFiches(filter);
    }
    filterFiches = (filter: FilterLightModel) => {
        let { fiches } = this.state;
        let time = filter.attributionDate?.getTime();
        let filteredFiches = _.filter(fiches, r => (filter.ue.identifier == '' || (r.ue.identifier == filter.ue.identifier))
            && (filter.state == 'Tous les statuts' || (r.state == filter.state) || (filter.state == "En attente d'attribution" && (r.state == "En attente d'attribution" || r.state == '')))
            && (!filter?.attributionDate || isNaN(time) || (new Date(r.attributionDate).getTime() <= time))
            && (filter.moe == '' || (r.moe.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(filter.moe.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))));
        this.setState({
            filter: filter,
            filteredFiches: filteredFiches
        });
    }
    //#endregion

    //#region fonctions d'Upload
    uploadFile = (file, toAdded: boolean) => {
        if (!toAdded)
            this.setState({
                openDialog: false,
                loading: true
            });
        else
            this.setState({
                loading: true
            });
        UploadService.ImportFile(file, toAdded)
            .then(res => {
                let body: ImportSheetLightModel = res.data;
                setTimeout(() => {
                    if (body) {
                        //Si la fiche existe déjà et que c'est une première injection du fichier
                        if (body.actionName === 2) {
                            this.handleDialogOpen(file.name, body.lastWriterFirstNameUser, body.lastWriterLastNameUser, body.lastWriteTime);
                        }
                        else {
                            if (body.lstErrors.length === 0) {
                                this.handleNewLstFiles(body.newLstFiles);
                                this.setState({
                                    isErrorToastVisible: false,
                                    isSuccessToastVisible: true,
                                    isInfoToastVisible: false,
                                    toastMessageSuccess: (body.actionName === 3) ? `La fiche "${file.name}" a bien été mise à jour !` : `Fiche "${file.name}" importée avec succès !`,
                                    loading: false
                                });
                            }
                            else {
                                this.setState({
                                    isSuccessToastVisible: false,
                                    isErrorToastVisible: true,
                                    isInfoToastVisible: false,
                                    toastMessageError: "",
                                    toastLstMessageError: body.lstErrors,
                                    files: [],
                                    loading: false
                                });
                            }
                            this.clearFiles();
                        }
                    }
                }, 0);
            })
            .catch(err => {
                this.setState({
                    isSuccessToastVisible: false,
                    isErrorToastVisible: true,
                    isInfoToastVisible: false,
                    toastMessageError: (!toAdded) ? `Erreur de mise à jour de la fiche "${file.name}": fichier non conforme.` : `Erreur d'import de la fiche "${file.name}": fichier non conforme.`,
                    toastLstMessageError: [],
                    loading: false,
                    files: []
                });
                this.clearFiles();
            });
    }

    handleChange = (files) => {
        this.setState({
            files: files
        });
        if (files && files.length > 0) {
            this.uploadFile(files[0], true);
        }
    }
    handleUploadReject = (files, e) => {
        let toastMessageError = "Erreur d'import.";
        _.each(files, f => {
            if (f.type != 'xlsx')
                toastMessageError += `Le ficher "${f.name}" n'est pas d'un type supporté (types supportés : "xlsx"). `;
            if (f.size > 10000000)
                toastMessageError += `Le ficher "${f.name}" est trop volumineux (taille limite acceptée : 10 Mo). `;
        });
        this.setState({
            isSuccessToastVisible: false,
            isInfoToastVisible: false,
            isErrorToastVisible: true,
            toastMessageError: toastMessageError,
            toastLstMessageError: [],
            files: [],
            loading: false
        });
    }

    //Effacer les fichiers du dropzone - Hack à changer
    clearFiles = () => {
        let elems: any = document.querySelectorAll("[class*=PreviewList-removeBtn]");
        elems.forEach(elem => {
            elem.click();
        });
    }
    handleUpdateFiche = () => {
        let files: any = this.state.files;
        if (files && files.length > 0)
            this.uploadFile(files[0], false);
    }
    //#endregion

    render() {
        let { filteredFiches, myAgencies, filter, selectedFiche } = this.state;
        let currentAgencyFilter = "";
        let currentStatusFilter = 0;
        let currentDateFilter = null;
        let currentMOEFilter = "MOE";
        let resetDisabled = (filter.state == 'Tous les statuts' && filter.ue.identifier == '' && !filter?.attributionDate && filter.moe == '');
        let currSelectedFiche = selectedFiche;

        currentAgencyFilter = filter?.ue?.identifier ? filter?.ue?.identifier : "";
        currentStatusFilter = filter?.state ? _.findIndex(states, s => {
            return s.state == filter?.state
        }) : 0;
        currentDateFilter = filter?.attributionDate ? filter?.attributionDate : currentDateFilter;
        currentMOEFilter = filter?.moe ? filter?.moe : "";

        return (
            this.state.loading ?
                <Box display="flex" flexDirection="row" justifyContent="center">
                    <Fade
                        in={this.state.loading}
                        style={{
                            transitionDelay: this.state.loading ? '800ms' : '0ms',
                        }}
                        unmountOnExit
                    >
                        <CircularProgress />
                    </Fade>
                </Box>
                :
                <>
                    <Box display="flex" width="100%" className="body-home" flexDirection="row" alignContent="center" p={2} style={{ 'height': '80vh' }} justifyContent="space-around">
                        <Box className="upload-box" style={{ 'height': '95%' }}>
                            <UploadFile handleChange={this.handleChange} handleRejectMessage={this.handleUploadReject} />
                        </Box>
                        <Box display="flex" height="95%" flexDirection="column" className="fiches">
                            <Box display="flex" height="25%" flexDirection="row" flexWrap="wrap" width="100%" ml={1} alignItems="center" justifyContent="space-between">
                                <Box display="flex" flex="nowrap" width="18%" alignItems="center">
                                    <FormControl className="form-control" disabled={myAgencies.length == 1}>
                                        <InputLabel shrink >
                                            Agence
                                        </InputLabel>
                                        <Select required disableUnderline
                                            value={currentAgencyFilter}
                                            onChange={(e) => this.handleChangeAgency(e)}
                                            displayEmpty
                                        >
                                            {myAgencies.map((item, index) =>
                                                <MenuItem value={item.identifier} key={`option-ue-${index}`} >
                                                    {item.label}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" flex="nowrap" width="18%" alignItems="center">
                                    <FormControl className="form-control">
                                        <InputLabel shrink>
                                            Statut
                                        </InputLabel>
                                        <Select required disableUnderline
                                            value={currentStatusFilter}
                                            label="Statut"
                                            onChange={(e) => this.handleChangeState(e)}
                                            displayEmpty>
                                            {states.map((item, index) =>
                                                <MenuItem value={item.id} key={`option-ue-${index}`} >
                                                    {item.state}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" flex="nowrap" width="18%" alignItems="center">
                                    <FormControl className="form-control">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                invalidDateMessage="Format invalide (format accepté dd/MM/yyy)"
                                                format="dd/MM/yyyy"
                                                id="date-picker-inline"
                                                label="Date d'attribution avant le"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={currentDateFilter}
                                                onChange={(e) => this.handleChangeAtrDate(e)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Box>
                                <Box display="flex" flex="nowrap" width="18%" alignItems="center">
                                    <FormControl className="form-control">
                                        <TextField
                                            value={currentMOEFilter}
                                            label="Maître d'oeuvre"
                                            onChange={(e) => this.handleChangeMOEFilter(e)}
                                            InputLabelProps={{
                                                shrink: true
                                            }} />
                                    </FormControl>
                                </Box>
                                <Box display="flex" flex="nowrap" width="18%" alignItems="center">
                                    <Button color="primary" className="btn-actions" onClick={this.resetFilter} disabled={resetDisabled}>
                                        Réinitialiser
                                </Button>
                                </Box>
                            </Box>
                            {filteredFiches.length == 0 ?
                                <Box style={{ flex: 1 }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    className="no-filtered-fiches-text">Aucun résultats. Veuillez changer vos paramètres de recherche.</Box> :
                                <TableContainer component={Paper} className="table-fiches">
                                    <Table stickyHeader>
                                        <TableHead className="table-head">
                                            <TableRow>
                                                <TableCell>
                                                    <Typography className="column-head" variant="subtitle1">Agence</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className="column-head" variant="subtitle1">Numéro affaire</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className="column-head" variant="subtitle1">Désignation affaire</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className="column-head" variant="subtitle1">Maître d'oeuvre</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className="column-head" variant="subtitle1">Statut</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className="column-head" variant="subtitle1">Date d'attribution</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className="column-head" variant="subtitle1">Prix de réponse HT</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className="column-head" variant="subtitle1">Fiche chargée le</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className="column-head" variant="subtitle1">Editer</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="table-body">
                                            {filteredFiches.map((item, index) => {
                                                return (
                                                    <TableRow key={`item-${index}`}>
                                                        <TableCell className="value-field">{item.ue.label}</TableCell>
                                                        <TableCell className="value-field">{item.numAffaire}</TableCell>
                                                        <TableCell className="value-field">{item.designAffaire}</TableCell>
                                                        <TableCell className="value-field">{item?.moe ? item?.moe : ''}</TableCell>
                                                        <TableCell className="value-field">{item.state}</TableCell>
                                                        <TableCell className="value-field">{Utils.getShortDateTime(item.attributionDate)}</TableCell>
                                                        <TableCell className="value-field">
                                                            <NumberFormat value={item?.responsePrice ? item?.responsePrice : '0'} displayType={'text'} thousandSeparator={' '} suffix={'€'} />
                                                        </TableCell>
                                                        <TableCell className="value-field">{Utils.getShortDateTime(item.fileCreationTime)}</TableCell>
                                                        <TableCell className="value-field">
                                                            <IconButton onClick={() => this.handleOpenEdition(item)}>
                                                                <FontAwesomeIcon icon={faPen} style={{ paddingLeft: '4px', color: '#000000' }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>)
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Box>
                    </Box>
                    <Dialog onClose={this.handleDialogClose} aria-labelledby="customized-dialog-title" open={this.state.openDialog}>
                        <DialogTitle id="customized-dialog-title">
                            Alerte
                        </DialogTitle>
                        <DialogContent dividers>
                            <Typography gutterBottom>
                                {this.state.dialogMessage}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleUpdateFiche} color="primary">
                                Oui
                            </Button>
                            <Button onClick={this.handleDialogClose} color="primary">
                                Non
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.isErrorToastVisible}
                        autoHideDuration={50000}
                        onClose={this.handleErrorClose}>
                        <MySnackbarContentWrapper
                            variant="error"
                            message={
                                this.state.toastMessageError
                            }
                            lstmessage={
                                this.state.toastLstMessageError
                            }
                            onClose={this.handleErrorClose}
                        />

                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.isSuccessToastVisible}
                        autoHideDuration={5000}
                        onClose={this.handleSuccessClose}>
                        <MySnackbarContentWrapper
                            variant="success"
                            message={
                                this.state.toastMessageSuccess
                            }
                            onClose={this.handleSuccessClose}
                        />
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.isInfoToastVisible}
                        autoHideDuration={5000}
                        onClose={this.handleInfoClose}>
                        <MySnackbarContentWrapper
                            variant="info"
                            message={
                                this.state.toastMessageInfo
                            }
                            onClose={this.handleInfoClose}
                        />
                    </Snackbar>
                    {this.state.isFicheDrawerOpened ? (
                        <FicheDrawerComponent handleGetLastFiches={this.getMyFiches} handleDialogEditionSuccess={this.handleDialogEditionSuccess} selectedFiche={currSelectedFiche} onClose={this.handleCloseDrawer} closeIcon={true} headerBorder={true}
                            isFicheDrawerOpened={this.state.isFicheDrawerOpened} />) : ''
                    }
                </>
        );
    }
}
