import React, { Component } from 'react';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Route } from 'react-router';
import { AppService } from './AppService';
import { authContext } from 'Auth';
import { UserAuthorizedLightModel } from './model/UserAuthorizedLightModel';
import './index.scss';

interface AppState {
  isUserAuthorized: boolean,
  lastAccess?: Date,
  loading: boolean
}

export default class App extends Component<{}, AppState> {
  static displayName = App.name;

  constructor(props){
    super(props);

    this.state={
      isUserAuthorized: false,
      loading: true,
      lastAccess: null
    };
    }

  componentDidMount(){
    this.getAuthorizedUser();
  }

  getAuthorizedUser(){
    AppService.getUserAuthorized(authContext.getCachedUser().profile.unique_name)
    .then(res=>{
      let _authorized: UserAuthorizedLightModel;
      if(res && res.data){
        _authorized=res.data;
        this.setState({
          isUserAuthorized: _authorized.isUserAuthorized,
          lastAccess: _authorized.lastAccess,
          loading: false
        });
      }
      else{
        this.setState({
          isUserAuthorized: false,
          lastAccess: null,
          loading: false
        });
      }
      
    })
    .catch(e=>
    {
      this.setState({
        isUserAuthorized: false,
        lastAccess: null,
        loading: false
      });
      console.log(e);
    });
  }

  render () {
    return (
      <Layout isUserAuthorized={this.state.isUserAuthorized} lastAccess={this.state.lastAccess} loading={this.state.loading}>
        <Route exact path='/' component={Home} />
      </Layout>
    );
  }
}
