import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

  
const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: '0.5rem',
    maxHeight: '60vh',
    overflow: 'auto'
  },
}));

  export function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
      
    const { className, message, lstmessage, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];
  
    return (
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
            message={
                <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center">
                    <Box width='5%'>
                        <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    </Box>
                    <Box width='90%' display="flex" flexGrow={2} className={classes.message} style={{ 'marginLeft': '10px' }}>
                        <Box display="flex" flexDirection="column" alignContent="center">
                    <div>
                        {message}
                    </div>
                    {lstmessage && lstmessage.length>0?
                        lstmessage.map((elem, index)=>{
                        return(
                            <div key={`err-${index}`}>
                                - {elem}
                            </div>
                        )
                        })
                        : ''
                    }
                    </Box>
                    </Box>
                    <Box width='5%' justifyContent="flex-end" style={{ 'marginRight': '10px'}}>
                        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose} style={{'paddingTop': '0px'}}>
                        <CloseIcon className={classes.icon} />
                        </IconButton>
                    </Box>
                </Box>
        }
        {...other}
      />
    );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  lstmessage: PropTypes.array,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};