import { AuthenticationContext, AdalConfig } from 'react-adal';
import { ConfigService } from './configService';

function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

var _config = new ConfigService().Config();
var adalConfig: AdalConfig = _config.adalConfig;
adalConfig.postLogoutRedirectUri = window.location.origin;
if (detectIE())
    adalConfig.cacheLocation = 'localStorage';
export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => {
    var ac = authContext.getCachedToken(authContext.config.clientId, );
    return ac;
};

export const logOut = () =>{
    return authContext.logOut();
}

export const getProfile=()=>{
    return authContext.getCachedUser().profile;
}
export const getInitialsUser=(user)=>{
    let initials='';
    if(user){
        initials = `${user.given_name && user.given_name.length>0 ?user.given_name[0]:''}${user.family_name && user.family_name.length>0 ?user.family_name[0]:''}`
    }
    return initials;
}
export const getFullUser=(user)=>{
    let res='';
    if(user){
        res = `${user.given_name} ${user.family_name}`;
    }
    return res;
}