/*eslint-disable*/
import { AdalConfig } from 'react-adal';

interface IConfig {
    azureMapKey: any;
    bingmapKey: string;
    adalConfig: AdalConfig,
    minimumLoadCapacity: number
}

export class ConfigService {
    _config: IConfig = null;
    _configRequested: boolean = false;

    Config(): IConfig {
        if (this._configRequested)
            return this._config;
        
        var client = new XMLHttpRequest();
        client.onload=()=>{
            let x:any;
            if (client.readyState === 4 && client.status === 200) {
                x=JSON.parse(client.responseText);
            }
            this._config=x;
        };

        //false for synchronous xhr
        client.open('GET', 'config/appsettings.json', false);
        client.send(null);

        this._configRequested = true;
        this._config.adalConfig.redirectUri = window.location.protocol + '//' + window.location.host;
        return this._config;
    }
}