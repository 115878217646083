import React, { Component } from 'react';
import './NavMenu.scss';
import { AppBar, Box, Tooltip, Avatar, IconButton } from '@material-ui/core';
import { authContext, getFullUser, getInitialsUser, logOut } from 'Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

interface NavMenuProperties{

}
interface NavMenuState{
  openMenu: boolean
}
export class NavMenu extends Component<NavMenuProperties, NavMenuState> {
  static displayName = NavMenu.name;
  anchorRef;

  constructor (props) {
    super(props);

    this.anchorRef=React.createRef();

    this.state = {
      openMenu: false
    };
  }

  isIE11=()=>{
    return !!navigator.userAgent.match(/Trident.*rv[ :]*11\./);
  }

  getDebit=()=>{
    let res="";
    if(!this.isIE11())
    {
      try{
        let _navigator:any = navigator;
        res = _navigator.connection.downlink;
      }catch(e){}
    }
    return res;
  }

  disconnect=()=>{
    logOut();
  }

  handleToggleMenu=()=>{
    this.setState({openMenu:true});
  }

  handleCloseMenu=()=>{
    this.setState({openMenu:false});
  }

  render () {
    return (
      <>
        <AppBar className="nav-menu-colas" position="fixed">
          <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center">
            <Box width="75%" display="flex" pl={3} pr={2} justifyContent="flex-start" flexDirection="row" alignItems="center">
              <Box alignSelf='center' pt={2}>
                <Tooltip title={`Débit : ${this.getDebit()} Mbps`}>
                  <img src='/Colas_Digital Solutions.jpg' width='100px' height='50px' alt='Colas Digital Solutions'/>
                </Tooltip>
              </Box>
            </Box>
            <Box width="25%" display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="flex-end" pl={2} pr={2} pt={0} pb={0} alignItems="center">
              <Box>
                <Tooltip title={getFullUser(authContext.getCachedUser().profile)} placement="left">
                  <Avatar className="initial-profile">
                    {getInitialsUser(authContext.getCachedUser().profile)}
                  </Avatar>
                </Tooltip>
              </Box>
              <Box pl={1} alignSelf='flex-start'>
                <Tooltip title='Se déconnecter' placement="bottom">
                  <IconButton size={"small"} onClick={this.disconnect}>
                    <FontAwesomeIcon color="white" icon={faSignOutAlt}/>
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </AppBar>
      </>
    );
  }
}
