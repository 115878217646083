import React from 'react';
import { Dialog, IconButton, Typography, TextField, Box, FormControl, Select, MenuItem, Button, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NumberFormat from 'react-number-format';
import { FicheService } from '../FicheService';
import { FicheLightModel } from '../../model/FicheLightModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import "./FicheComponent.scss";
import * as _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";


interface NumberFormatCustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const AmountFormatCustom = React.forwardRef<NumberFormat<number>, NumberFormatCustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                allowNegative={false}
                thousandSeparator={' '}
                isNumericString
                suffix="€"
            />
        );
    },
);




interface FicheDrawerProperties {
    render?: any,
    isFicheDrawerOpened: boolean,
    closeIcon?: boolean,
    dialogTitleComponent?: JSX.Element,
    headerBorder?: boolean,
    selectedFiche: FicheLightModel,
    onClose: () => void,
    handleDialogEditionSuccess: () => void,
    handleGetLastFiches: () => void
}

interface FicheDrawerState {
    fiche: FicheLightModel,
    stateTouched: boolean,
    dateAttributionTouched: boolean,
    finalising: boolean,
    invalidForm: boolean,
    baseFiche: FicheLightModel
}

export const states = [
    {
        id: 0,
        state: "Gagné"
    },
    {
        id: 1,
        state: "Perdu"
    },
    {
        id: 2,
        state: "En attente d'attribution"
    },
    {
        id: 3,
        state: "Excusé"
    },
    {
        id: 4,
        state: "Non soumissionné"
    },
    {
        id: 5,
        state: "Infructueux"
    }
];

export const challengers = [
    {
        id: 0,
        name: "Autre"
    },
    {
        id: 1,
        name: "EUROVIA"
    },
    {
        id: 2,
        name: "EIFFAGE"
    },
    {
        id: 3,
        name: "NGE"
    },
    {
        id: 4,
        name: "COLAS"
    }
];

export const rangs = [
    {
        id: 0,
        rang: "1"
    },
    {
        id: 1,
        rang: "2"
    },
    {
        id: 2,
        rang: "3"
    },
    {
        id: 3,
        rang: "4"
    },
    {
        id: 4,
        rang: "5"
    },
    {
        id: 5,
        rang: "6"
    },
    {
        id: 6,
        rang: "7"
    },
    {
        id: 7,
        rang: "8"
    },
    {
        id: 8,
        rang: "9"
    },
    {
        id: 9,
        rang: "10"
    },
    {
        id: 10,
        rang: "> 10"
    }
];

export class FicheDrawerComponent extends React.Component<FicheDrawerProperties, FicheDrawerState>{

    constructor(props) {
        super(props);
        let _fiche = null;
        if (this.props.selectedFiche != null) {
            _fiche = this.props.selectedFiche;
            let atrDate = new Date(this.props.selectedFiche.attributionDate);
            _fiche.attributionDate = atrDate.getFullYear() < 2000 ? null : _fiche.attributionDate;
        }
        this.state = {
            fiche: _fiche,
            stateTouched: false,
            dateAttributionTouched: false,
            finalising: true,
            invalidForm: true,
            baseFiche: null
        };
    }

    componentDidMount() {
        this.getBaseFiche();
    }

    getBaseFiche = () => {
        let { fiche } = this.state;
        FicheService.getFiche(fiche.id)
            .then(res => {
                let _data = res.data ? res.data : null;
                this.setState({
                    baseFiche: _data,
                    finalising: false
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    saveFiche = () => {
        let { fiche } = this.state;
        this.setState({
            finalising: true
        });
        if (fiche.state != "Gagné" && fiche.state != "Perdu") {
            fiche.resultatAttributionLightModel.rangColas = null;
            fiche.resultatAttributionLightModel.nomAttributaire = null;
            fiche.resultatAttributionLightModel.montantOffreAttributaire = null;
            fiche.resultatAttributionLightModel.montantOffreChallenger = null;
            fiche.resultatAttributionLightModel.nomChallengerPrincipal = null;
        }
        let lightFiche = {
            Id: fiche.id,
            AttributionDate: fiche.attributionDate,
            State: fiche.state,
            ResultatAttributionLightModel: {
                Id: fiche.resultatAttributionLightModel.id,
                NomAttributaire: fiche.resultatAttributionLightModel.nomAttributaire,
                NomChallengerPrincipal: fiche.resultatAttributionLightModel?.nomChallengerPrincipal == 'Autre' ? '0' : fiche.resultatAttributionLightModel.nomChallengerPrincipal,
                NomChallengerAutre: fiche.resultatAttributionLightModel?.nomChallengerPrincipal == 'Autre' ? fiche?.resultatAttributionLightModel?.nomChallengerAutre : '0',
                RangColas: fiche.resultatAttributionLightModel?.rangColas ? Math.min(fiche.resultatAttributionLightModel?.rangColas, 10) : null,
                MontantOffreAttributaire: fiche.resultatAttributionLightModel.montantOffreAttributaire,
                MontantOffreChallenger: fiche.resultatAttributionLightModel.montantOffreChallenger
            }
        }
        FicheService.saveFiche(lightFiche).then(res => {
            if (res?.data?.lstErrors?.length == 0) {
                this.props.handleDialogEditionSuccess();
                this.props.handleGetLastFiches();
            }
        })
            .catch(e => {
                console.log(e);
            });
    }

    handleChangeState = (e) => {
        let { fiche, invalidForm } = this.state;
        fiche.state = states[e.target.value].state;
        if (e.target.value == 0) {
            fiche.resultatAttributionLightModel.nomAttributaire = 'COLAS';
            fiche.resultatAttributionLightModel.rangColas = 1;
            fiche.resultatAttributionLightModel.montantOffreAttributaire = fiche?.responsePrice;
            if (fiche.resultatAttributionLightModel.nomChallengerPrincipal == 'COLAS') {
                fiche.resultatAttributionLightModel.nomChallengerPrincipal = '';
                fiche.resultatAttributionLightModel.montantOffreChallenger = null;
                fiche.resultatAttributionLightModel.rangColas = null;
            }
        }
        else if (e.target.value == 1) {
            if (fiche.resultatAttributionLightModel.nomAttributaire == 'COLAS') {
                fiche.resultatAttributionLightModel.nomAttributaire = '';
                fiche.resultatAttributionLightModel.montantOffreAttributaire = null;
                fiche.resultatAttributionLightModel.rangColas = null;
            }
            fiche.resultatAttributionLightModel.nomChallengerPrincipal = 'COLAS';
            fiche.resultatAttributionLightModel.montantOffreChallenger = fiche?.responsePrice;
        }
        else {
            fiche.resultatAttributionLightModel.rangColas = null;
            fiche.resultatAttributionLightModel.nomAttributaire = null;
            fiche.resultatAttributionLightModel.montantOffreAttributaire = null;
            fiche.resultatAttributionLightModel.montantOffreChallenger = null;
            fiche.resultatAttributionLightModel.nomChallengerPrincipal = null;
        }
        if (!fiche.attributionDate || !fiche.state)
            invalidForm = true;
        else
            invalidForm = false;
        this.setState({
            fiche: fiche,
            invalidForm: invalidForm
        });
    }

    handleChangeFileCreationDate = (option) => {
        let { fiche, invalidForm } = this.state;
        fiche.attributionDate = option;
        if (!fiche.attributionDate || isNaN(fiche?.attributionDate.getTime()) || !fiche.state)
            invalidForm = true;
        else
            invalidForm = false;
        this.setState({
            fiche: fiche,
            invalidForm: invalidForm
        });
    }

    handleChangeAssignee = (option) => {
        let { fiche, invalidForm } = this.state;
        fiche.resultatAttributionLightModel.nomAttributaire = option.target.value;
        if (!fiche.attributionDate || !fiche.state)
            invalidForm = true;
        else
            invalidForm = false;
        this.setState({
            fiche: fiche,
            invalidForm: invalidForm
        });
    }

    handleChangeAssigneeOffer = (option) => {
        let { fiche, invalidForm } = this.state;
        if (option.target.value == "" && !fiche.resultatAttributionLightModel.montantOffreAttributaire)
            return;
        fiche.resultatAttributionLightModel.montantOffreAttributaire = Number(option.target.value);
        if (!fiche.attributionDate || !fiche.state)
            invalidForm = true;
        else
            invalidForm = false;
        this.setState({
            fiche: fiche,
            invalidForm: invalidForm
        });
    }

    handleChangeChallengerOffer = (option) => {
        let { fiche, invalidForm } = this.state;
        if (option.target.value == "" && !fiche.resultatAttributionLightModel.montantOffreChallenger)
            return;
        fiche.resultatAttributionLightModel.montantOffreChallenger = Number(option.target.value);
        if (!fiche.attributionDate || !fiche.state)
            invalidForm = true;
        else
            invalidForm = false;
        this.setState({
            fiche: fiche,
            invalidForm: invalidForm
        });
    }

    handleChangeOtherChallenger = (option) => {
        let { fiche, invalidForm } = this.state;
        fiche.resultatAttributionLightModel.nomChallengerAutre = option.target.value;
        if (!fiche.attributionDate || !fiche.state)
            invalidForm = true;
        else
            invalidForm = false;
        this.setState({
            fiche: fiche,
            invalidForm: invalidForm
        });
    }

    handleChangeColasRank = (e) => {
        let { fiche, invalidForm } = this.state;
        fiche.resultatAttributionLightModel.rangColas = e.target.value + 1;
        if (!fiche.attributionDate || !fiche.state)
            invalidForm = true;
        else
            invalidForm = false;
        this.setState({
            fiche: fiche,
            invalidForm: invalidForm
        });
    }

    handleChangeChallenger = (e) => {
        let { fiche, invalidForm, baseFiche } = this.state;
        let chall = challengers[e.target.value].name;
        fiche.resultatAttributionLightModel.nomChallengerPrincipal = (chall == 'Autre') ? 'Autre' : challengers[e.target.value].name;
        fiche.resultatAttributionLightModel.nomChallengerAutre = '';
        if (!fiche.attributionDate || !fiche.state || fiche == baseFiche)
            invalidForm = true;
        else
            invalidForm = false;
        this.setState({
            fiche: fiche,
            invalidForm: invalidForm
        });
    }

    closeDialog = () => {
        let { baseFiche } = this.state;
        this.setState({
            fiche: baseFiche
        });
        this.props.onClose();
    }

    render() {
        const { isFicheDrawerOpened, closeIcon, headerBorder } = this.props;
        let { fiche } = this.state;

        let state = fiche?.state ? _.findIndex(states, s => {
            return s.state == fiche?.state
        }) : '';

        let attributionDate = fiche.attributionDate;
        let assignee = fiche.resultatAttributionLightModel.nomAttributaire;
        let assigneeOffer = fiche.resultatAttributionLightModel.montantOffreAttributaire;
        let colasRank = fiche.resultatAttributionLightModel?.rangColas ? _.findIndex(rangs, s => {
            return +s.rang == Math.min(fiche.resultatAttributionLightModel?.rangColas, 10)
        }) : '';

        //tochange 
        if (fiche.resultatAttributionLightModel?.rangColas == 11)
            colasRank = 10;

        let challenger = _.findIndex(challengers, s => {
            return s.name == fiche?.resultatAttributionLightModel.nomChallengerPrincipal
        });
        let challengerOffer = fiche.resultatAttributionLightModel.montantOffreChallenger;

        let resestAmountWarning = (state > 1) && (challenger != -1 || assignee != null || assigneeOffer != null || challengerOffer != null || colasRank != "");

        let otherChallenger = (fiche.resultatAttributionLightModel.nomChallengerAutre && challenger == 0) ? fiche.resultatAttributionLightModel.nomChallengerAutre : '';

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                onClose={this.closeDialog}
                aria-labelledby="simple-dialog-title"
                open={isFicheDrawerOpened}
                disableEnforceFocus={true}
                classes={{
                    paper: "drawer-fiche-paper"
                }}>
                <DialogTitle id="simple-dialog-title" disableTypography className={headerBorder ? 'dialog-header-border' : ''}>
                    <Box display="flex" flexDirection="row" alignItems="center" width="100%">
                        <Box width="90%">
                            <Typography className="header-title" variant="h6">Mise à jour de l'affaire</Typography>
                        </Box>
                        <Box width="10%" textAlign="end">
                            {closeIcon ? (
                                <>
                                    <IconButton aria-label="close" onClick={this.closeDialog} className="dialog-close-button">
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            ) : null}
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box display="flex" flex="wrap" flexDirection="column" className="fiche-form-content" p={1}>
                        <Box display="flex" flex="wrap" alignItems="center" flexDirection="row" m={1} justifyContent="flex-start">
                            <Box width="20%">
                                <Typography className={`${!attributionDate || (attributionDate > new Date()) ? 'mandatory-field' : 'important-field'}`} variant="subtitle1">Date d'attribution</Typography>
                            </Box>
                            <Box display="flex" flex="nowrap" width="20%">
                                <FormControl className="form-control">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            disabled={this.state.finalising}
                                            variant="inline"
                                            invalidDateMessage="Format de date non valide (format accepté dd/MM/yyy)"
                                            format="dd/MM/yyyy"
                                            id="date-picker-inline"
                                            value={attributionDate}
                                            onChange={(e) => this.handleChangeFileCreationDate(e)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Box>
                            <Box width="20%" alignContent="end">
                                <Typography className={`${state === '' ? 'mandatory-field' : 'important-field'}`} variant="subtitle1">Statut</Typography>
                            </Box>
                            <Box display="flex" flex="nowrap" width="20%">
                                <FormControl className="form-control">
                                    <Select required disableUnderline
                                        value={state}
                                        disabled={this.state.finalising}
                                        onChange={(e) => this.handleChangeState(e)}
                                        displayEmpty
                                    >
                                        {states.map((item, index) =>
                                            <MenuItem value={item.id} key={`option-state-${index}`} >
                                                {item.state}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box width="15%">
                                <Typography className="precision-field" variant="subtitle1">Rang de Colas</Typography>
                            </Box>
                            <Box display="flex" flex="nowrap" width="5%">
                                <FormControl className="form-control">
                                    <Select required disableUnderline
                                        value={colasRank}
                                        disabled={state > 1 || this.state.finalising}
                                        onChange={(e) => this.handleChangeColasRank(e)}
                                        displayEmpty
                                    >
                                        {rangs.map((item, index) =>
                                            <MenuItem value={item.id} key={`option-rang-${index}`} >
                                                {item.rang}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" flex="wrap" alignItems="center" flexDirection="row" m={1} justifyContent="flex-start">
                            <Box width="20%" flexDirection="row">
                                <Typography className="important-field" variant="subtitle1">Attributaire</Typography>
                                <Typography className="precision-field" variant="subtitle1"> | Colas si gagné</Typography>
                            </Box>
                            <Box display="flex" flex="nowrap" width="20%">
                                <FormControl className="form-control">
                                    <TextField value={assignee || ''}
                                        disabled={state != 1 || this.state.finalising}
                                        onChange={(e) => this.handleChangeAssignee(e)}
                                        InputLabelProps={{
                                            shrink: true
                                        }} />
                                </FormControl>
                            </Box>
                            <Box width="20%">
                                <Typography className="important-field" variant="subtitle1">Montant de l'offre attributaire HT</Typography>
                            </Box>
                            <Box display="flex" flex="nowrap" width="20%">
                                <FormControl className="form-control" >
                                    <TextField value={assigneeOffer || ''}
                                        disabled={state != 1 || this.state.finalising}
                                        onChange={(e) => this.handleChangeAssigneeOffer(e)}
                                        InputProps={{
                                            inputComponent: AmountFormatCustom as any
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }} />
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" flex="wrap" alignItems="center" flexDirection="row" m={1} justifyContent="flex-start">
                            <Box width="20%" flexDirection="row">
                                <Typography className="important-field" variant="subtitle1">Challenger</Typography>
                                <Typography className="precision-field" variant="subtitle1"> | Colas si perdu (acteur principal)</Typography>
                            </Box>
                            <Box display="flex" flex="nowrap" width="20%">
                                <FormControl className="form-control">
                                    <Select disableUnderline
                                        value={challenger}
                                        disabled={state != 0 || this.state.finalising}
                                        onChange={(e) => this.handleChangeChallenger(e)}
                                        displayEmpty
                                    >
                                        {challengers.map((item, index) =>
                                            <MenuItem value={item.id} key={`option-chall-${index}`} >
                                                {item.name}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box width="20%">
                                <Typography className="important-field" variant="subtitle1">Montant de l'offre challenger HT</Typography>
                            </Box>
                            <Box display="flex" flex="nowrap" width="20%">
                                <FormControl className="form-control">
                                    <TextField value={challengerOffer || ''}
                                        disabled={state != 0 || this.state.finalising}
                                        onChange={(e) => this.handleChangeChallengerOffer(e)}
                                        InputProps={{
                                            inputComponent: AmountFormatCustom as any
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }} />
                                </FormControl>
                            </Box>
                        </Box>
                        {challenger == 0 ?
                            <Box display="flex" flex="wrap" alignItems="center" flexDirection="row" m={1} justifyContent="flex-start">
                                <Box width="20%" flexDirection="row">
                                    <Typography className="important-field" variant="subtitle1">Challenger</Typography>
                                    <Typography className="precision-field" variant="subtitle1"> (autre acteur)</Typography>
                                </Box>
                                <Box display="flex" flex="nowrap" width="20%">
                                    <FormControl className="form-control">
                                        <TextField value={otherChallenger}
                                            disabled={state != 0 || this.state.finalising}
                                            onChange={(e) => this.handleChangeOtherChallenger(e)}
                                            InputLabelProps={{
                                                shrink: true
                                            }} />
                                    </FormControl>
                                </Box>
                            </Box>
                            : ''}

                    </Box>
                </DialogContent>
                <DialogActions>
                    {this.state.finalising ?
                        <Box display="flex" className="fiche-form-footer" textAlign="end" justifyContent="flex-end">
                            <CircularProgress size={26} />
                        </Box>
                        :
                        <Box display="flex" flexDirection="row" alignItems="center" className="fiche-form-footer" textAlign="end" justifyContent="flex-end">
                            {resestAmountWarning ?
                                <Box width="80%">
                                    <Typography className="warning-field" variant="body1">La sélection du statut "{fiche.state}" va réinitialiser les champs "Attributaire", "Montant de l'offre attributaire HT", "Challenger" et "Montant de l'offre challenger HT"</Typography>
                                </Box> : ''}
                            {resestAmountWarning ?
                                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#F7C631', marginRight: '20px' }} />
                                : ''}

                            <Button color="primary" className="btn-actions" onClick={this.saveFiche} disabled={this.state.finalising}>
                                Mettre à jour
                            </Button>
                        </Box>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}