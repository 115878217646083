import * as axios from 'axios';
import { getToken, logOut } from 'Auth';

axios.defaults.headers.post['Authorization']=`Bearer ${getToken()}`;
axios.defaults.headers.get['Authorization']=`Bearer ${getToken()}`;

//En cas de session expirée, l'utilisateur est redirigé vers la page d'authentification
axios.interceptors.response.use(undefined, err => {
    if(err.response.status === 401){
        alert("Votre session est expirée!");
        logOut();
        //Redirect("/");
    }
});