import React from 'react';
import { NavMenu } from './NavMenu';
import { /*Container,*/ Fade, CircularProgress, Box } from '@material-ui/core';
import { Utils } from 'Helper/Utils';

interface LayoutProperties{
  isUserAuthorized: boolean,
  lastAccess?: Date,
  loading: boolean
}
interface LayoutState{

}
export class Layout extends React.PureComponent<LayoutProperties, LayoutState> {
  static displayName = Layout.name;

  
  render () {
    return(
      this.props.loading ?
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Fade
          in={this.props.loading}
          style={{
            transitionDelay: this.props.loading ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>
      </Box>
      :
      this.props.isUserAuthorized ? 
      <div>
        <NavMenu />
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box style={{'fontWeight':'bold', 'fontSize': '1.2rem', 'color': 'gray'}} pb={3}>
            {
              this.props.lastAccess ?(
                <span>Dernier Accès le : {Utils.getShortDateTime(this.props.lastAccess)}</span>
              ):'Première connexion'
            }
          </Box>
        </Box> 
        {/*<Container maxWidth={false}>
          {this.props.children}
        </Container>*/}
        <div className="jumbotron">
            <p>
            Nous vous remercions de penser à mettre vos devis sur Pricing mais
            cette plateforme ne peut plus être utilisée.
            </p><p>
            Merci de déposer vos feuilles de vente enrichies directement via
            l’outils QDV.
            </p><p>
            Un mémo détaillant la procédure de dépôt depuis QDV sur vous a été
            envoyé par l’ENT et a également été publié sur le teams E-projets.
            </p>
        </div>
      </div>
      :<div className="jumbotron">
                    <div className="jumbotron">
                        <p>
                            Nous vous remercions de penser à mettre vos devis sur Pricing mais
                            cette plateforme ne peut plus être utilisée.
                        </p><p>
                            Merci de déposer vos feuilles de vente enrichies directement via
                            l’outils QDV.
                        </p><p>
                            Un mémo détaillant la procédure de dépôt depuis QDV sur vous a été
                            envoyé par l’ENT et a également été publié sur le teams E-projets.
                        </p>
                    </div>
      </div>
    );
  }
}
